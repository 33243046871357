/* The following block can be included in a custom.scss */

/* make the customizations */
// $theme-colors: (
//     // "info": tomato,
//     // "danger": teal
// );

$primary: #346ab0;
$secondary: #85c0d7;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Light.ttf') format("truetype");
    font-weight: 100 300;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-LightItalic.ttf') format("truetype");
    font-weight: 100 300;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Italic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Medium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
}