@import "dimmia_custom";

// ::-webkit-scrollbar {
//     width: 5px !important;
//     height: 5px !important;
// }
// ::-webkit-scrollbar-thumb {
//     background-color: $secondary;
//     border-radius: 10px;
// }
// ::-webkit-scrollbar-thumb:hover {
//     background-color: $primary;
// }
// ::-webkit-scrollbar-track {
//     background: transparent;
// }

* {
    font-family: "Roboto", sans-serif;
}

body {
    background-color: $light;
    overscroll-behavior: none;
}

.w-15 {
    width: 15% !important;
}

.opacity-25{
    opacity: .25;
}

#app-container {
    width: 100%;
    height: 100vh;
    background-color: #edf8fa;
    position: relative;
    overflow-x: hidden;

    #ellipsis{
        background-color: #3789a9;
        width: 100%;
        height: 100%;
        clip-path: ellipse( 90% 101% at 5% 0%);
        position: absolute;
        top: 0;
        left: 0;
        z-index:0;
    }

    #flottante {
        position:relative;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(xxl) {
            width: calc(100% - 38px);
            max-width: 1600px;
            height: calc(100% - 38px);
            margin: auto;
            padding-top: 19px;
        }
    }

    #websocket-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1050;
    }

    #feedback-notifiche {
        .feedback-notifica-success,
        .feedback-notifica-error {
            width: 80%;
            @include media-breakpoint-up(md) {
                width: 40%;
            }
            margin: 0 auto;
            padding: 8px;
        }

        .feedback-notifica-success {
            background-color: #c5e1d4;
            color: $success;
        }

        .feedback-notifica-error {
            background-color: #f6ccd0;
            color: $danger;
        }
    }

    #login {
        width: 100%;
        height: 100%;

        background-color: #51a5c660;

        .confirm-button {
            min-width: 50%;
        }

        .form-login {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 75%;
            }
        }

        #dimmia-titolo-logo {
            width: 250px;

            @include media-breakpoint-up(md) {
                width: 400px;
                // top: 80px;
            }

            // position: absolute;
            // top: 40px;
            // left: 50%;
            // transform: translateX(-50%);
        }
        #login-wrapper {
            max-width: 90% !important;

            .login-card {
                .servizio {
                    text-align: center;
                    @include media-breakpoint-up(sm) {
                        text-align: left;
                    }
                }

                .login-card-body {
                    overflow-y: auto;
                    max-width: 100%;
                    width: 750px;
                    min-height: 200px;

                    .login-card-img {
                        width: 90px;
                        height: 90px;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    #pannello-left {
        position: relative;

        #titolo-lista-chat,
        #titolo_pannello_generico {
            height: 60px;
        }

        .filtro-categorie {
            .react-select__menu-list::-webkit-scrollbar {
                display: none;
            }

            .react-select__menu-list {
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
        }

        #lista-chat-wrapper {
            height: calc(
                100% - 60px
            ); // altezza precisa del totale meno altezza testata e altezza barra navigazione. così diventa tutto fisso tranne la lista scorrevole
            position: relative;

            #lista-chat {
                overflow: auto;
                height: calc(100% - 42px - 60px); // tutto meno altezza filtri meno bottoni azione

                &.column {
                    //filtri visualizzati in colonna
                    height: calc(100% - 81px - 60px);
                }
                padding-bottom: 15px;
                .lista {
                    box-shadow: 0px 24px 3px -24px $secondary;
                    margin-top: 1px;
                    background-color: #fff;

                    &.active {
                        font-weight: bold;
                        border-color: $primary !important;
                        color: $primary;
                        background-color: $light;
                        border: 1px solid;

                        .data {
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        border-color: $primary !important;
                        color: $primary;
                        background-color: $light;
                    }

                    .notifica {
                        vertical-align: top;
                        font-size: 11px;
                        font-weight: 500;
                    }

                    .data {
                        vertical-align: bottom;
                        font-size: 11px;
                        font-weight: 500;
                    }
                }
            }

            #btn-actions-wrapper {
                height: 60px;

                #btn-nuova-chat,
                #btn-filtra-lista-chat,
                #btn-archiviate {
                    width: 42px;
                    height: 42px;
                    line-height: 42px;
                    text-align: center;
                    border-radius: 50%;
                }
            }
        }

        #archiviate-wrapper {
            position: relative;

            overflow: auto;
            height: calc(100% - 60px); // tutto meno altezza filtri meno bottoni azione
            padding-bottom: 40px;

            .lista {
                box-shadow: 0px 24px 3px -24px $secondary;
                margin-top: 1px;
                background-color: #fff;

                &.active {
                    font-weight: bold;
                    border-color: $primary !important;
                    color: $primary;
                    background-color: $light;
                    border: 1px solid;

                    .data {
                        font-weight: bold;
                    }
                }

                &:hover {
                    cursor: pointer;
                    border-color: $primary !important;
                    color: $primary;
                    background-color: $light;
                }

                .notifica {
                    vertical-align: top;
                    font-size: 11px;
                    font-weight: 500;
                }

                .data {
                    vertical-align: bottom;
                    font-size: 11px;
                    font-weight: 500;
                }
            }
        }

        #form-crea-chat {
            height: calc(100% - 60px);

            #crea-chat {
                height: calc(100% - 60px);
                overflow-y: auto;

                .utente {
                    box-shadow: 0px 24px 3px -24px $secondary;
                }
            }

            #confirm {
                height: 60px;
            }
        }

        #form-ricerca {
            height: calc(100% - 60px);

            #ricerca {
                height: calc(100% - 60px);
                overflow-y: auto;
                scroll-behavior: smooth;

                .lista {
                    box-shadow: 0px 24px 3px -24px $secondary;
                    margin-top: 1px;
                    background-color: #fff;

                    &.active {
                        font-weight: bold;
                        border-color: $primary !important;
                        color: $primary;
                        background-color: $light;
                        border: 1px solid;

                        .data {
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        border-color: $primary !important;
                        color: $primary;
                        background-color: $light;
                    }

                    .notifica {
                        vertical-align: top;
                        font-size: 11px;
                        font-weight: 500;
                    }

                    .data {
                        vertical-align: bottom;
                        font-size: 11px;
                        font-weight: 500;
                    }
                }
            }

            #confirm {
                height: 60px;
            }
        }
    }

    #pannello-right {
        #titolo-chat,
        #titolo_pannello_generico {
            height: 60px;
        }

        #lista-messaggi {
            height: calc(100% - 60px - 60px);
            overflow-y: auto;

            background-image: url("../img/dimmia_pattern_big.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;


            &.multiline {
                // faccio spazio per altre righe di messaggio
                height: calc(100% - 60px - 120px);
            }

            .messaggio-buttons{
                .btn-action{
                    background-color: #57595D88;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    line-height: 25px;
                    text-align: center;
                }
            }

            .msg {
                min-width: 180px;
                max-width: 100%;

                @include media-breakpoint-up(lg) {
                    max-width: 65%;
                }

                padding: 8px 8px 15px 8px;
                position: relative;
                overflow-x: hidden;

                &.active {
                    border: 2px solid $primary !important;
                }

                .testo {
                    span {
                        vertical-align: text-bottom;
                    }
                }

                .data {
                    font-size: 10px;
                    font-weight: 600;
                    position: absolute;
                    right: 4px;
                    bottom: 0;
                }
            }

            .msg_out {
                justify-content: flex-start;
                flex-direction: row-reverse;
                .msg {
                    background-color: $secondary;
                }
            }

            .msg_in {
                justify-content: flex-start;
                flex-direction: row;
                .messaggio-buttons{
                    flex-direction: row-reverse;
                }
                .msg {
                    background-color: #fff;
                }
            }

            .msg_admin {
                justify-content: center;

                .msg {
                    background-color: $light;
                }
            }

            .blurrata {
                filter: blur(10px);
            }

            .img_wrapper {
                max-width: 100%;
                &.verticale {
                    width: 240px;
                    max-height: 344px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                &.orizzontale {
                    width: 330px;
                    overflow: hidden;
                    img {
                        height: 100%;
                    }
                }
                .helper {
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }
            }

            .audio_wrapper {
                min-width: 320px;
            }

            .file_wrapper,
            .video_wrapper {
                color: $dark;
                width: 150px;
                margin: 0 auto;
                a {
                    color: $dark;

                    &:hover {
                        color: $dark;
                    }
                }

                img {
                    width: 80%;
                }
            }

            .emoji-mart-emoji:hover:before{
                display: none;
            }
        }

        #chat-dettaglio {
            height: calc(100% - 60px);
            overflow-y: auto;
            position: relative;

            #chat-dettaglio-descrizione {
                .show_more {
                    color: $primary;
                    &:hover {
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }

        #input-messaggio {
            bottom: 0;
            left: 0;
            height: 60px;

            &.multiline {
                // faccio spazio per altre righe di messaggio
                height: 120px;
                #msg {
                    border-radius: 18px;
                }
            }

            .progress {
                // non pulitissimo perchè sovrascrivo proprietà bootstrap
                width: 80%;
                background-color: #b9b9b9;
            }

            textarea#msg {
                line-height: 1.5;
            }

            #toggleEmoji {
                position: absolute;
                top: 50%;
                left: 15px;
                transform: translateY(-50%);
            }
        }

        #input-file {
            position: absolute;
            top: 60px;
            left: 0;

            width: 100%;
            height: calc(100% - 60px);

            background-color: #ffffff;

            #dropzone-wrapper {
                height: calc(100% - 60px);
            }

            #dropzone-inside {
                position: relative;

                padding: 20px;
                border-width: 2px;
                border-radius: 10px;
                border-style: dashed;
                height: 100%;

                .close_icon {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }

                #anteprima-immagini {
                    flex-wrap: wrap;
                    overflow-y: auto;

                    .anteprima {
                        position: relative;

                        .anteprima-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;

                            width: 100%;
                            height: 100%;

                            background-color: #000000dd;

                            .progress {
                                // non pulitissimo perchè sovrascrivo proprietà bootstrap
                                width: 80%;
                                background-color: #b9b9b9;
                            }

                            &.error {
                                background-color: $danger;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }

        #input-audio {
            .btn-recording {
                width: 44px;
                height: 44px;
            }
        }

        #modifica {
            #form-aggiungi-utenti {
                height: calc(100% - 60px);

                #aggiungi-utenti {
                    height: calc(100% - 60px);
                    overflow-y: auto;
                }

                #confirm {
                    height: 60px;
                }
            }

            #form-elimina-utenti {
                height: calc(100% - 60px);

                #elimina-utenti {
                    height: calc(100% - 60px);
                    overflow-y: auto;
                }

                #confirm {
                    height: 60px;
                }
            }

            #form-modifica-amministratore {
                height: calc(100% - 60px);

                #modifica-amministratore {
                    height: calc(100% - 60px);
                    overflow-y: auto;
                }

                #confirm {
                    height: 60px;
                }
            }

            #form-modifica-dettagli {
                height: calc(100% - 60px);

                #modifica-dettagli {
                    height: calc(100% - 60px);
                    overflow-y: auto;
                }

                #confirm {
                    height: 60px;
                }
            }
        }
    }
}

.whcenter {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor-pointer:hover {
    cursor: pointer;
    filter: brightness(0.8);
}

.shadow-top {
    box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 8%);
}

.tondo {
    border-radius: 50% !important;
}

// ANIMATIONS
.msg-fade-appear .msg,
.msg-fade-enter .msg {
    opacity: 0;
    transform: scale(0.9);
}
.msg-fade-appear-active .msg,
.msg-fade-enter-active .msg {
    opacity: 1;
    transform: scale(1);
    transition: opacity 100ms, transform 100ms;
}

.slide-enter {
    transform: translateX(-101%);
    border-right: 1px solid $light;
}
.slide-enter-active {
    transform: translateX(0);
    transition: transform 300ms ease-in;
    border-right: 1px solid $light;
}

.slide-exit {
    transform: translateX(0);
    border-right: 1px solid $light;
}
.slide-exit-active {
    transform: translateX(-101%);
    transition: transform 300ms ease-in;
    border-right: 1px solid $light;
}

.slide-right-enter {
    transform: translateX(+101%);
    border-left: 1px solid $light;
}
.slide-right-enter-active {
    transform: translateX(0);
    transition: transform 300ms ease-in;
    border-left: 1px solid $light;
}

.slide-right-exit {
    transform: translateX(0);
    border-left: 1px solid $light;
}
.slide-right-exit-active {
    transform: translateX(+101%);
    transition: transform 300ms ease-in;
    border-left: 1px solid $light;
}

.display-enter {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
}
.display-enter-active {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 1;

    transition: opacity 300ms ease-in;
}

.display-exit-active {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.display-exit-active {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;

    transition: opacity 300ms ease-in;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.8);
}
.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}
.fade-exit {
    transition: opacity 0ms, transform 0ms;
    display: none;
}
.fade-exit-active {
    transition: opacity 0ms, transform 0ms;
    display: none;
}

.emoji-fade-enter {
    opacity: 0;
}
.emoji-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
}
.emoji-fade-exit {
    opacity: 1;
}
.emoji-fade-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

.fade-slow-enter {
    opacity: 0;
    transform: scale(0.9);
}
.fade-slow-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 500ms;
}
.pulse {
    animation: pulse_primary 1s infinite;
}

@keyframes pulse_primary {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 106, 176, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(52, 106, 176, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 106, 176, 0);
    }
}
